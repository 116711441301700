import 'legacy/styles';
import 'imports/CommonCssImports';

import 'regenerator-runtime/runtime';
import 'entrypoints/common/public-path'
import lodash from 'lodash';
import momentTz from 'moment-timezone';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import jQuery from 'jquery';
import jQueryNoConflict from 'jquery-no-conflict';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import 'components/bng/ui/Tippy.css';
import tippy, {followCursor} from 'tippy.js';
import BimEventBus from "BimEventBus";

window.__BROWSER_TZ = '';

try {
    window.__BROWSER_TZ = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone ?? '';
} catch (e) {
    console.error('Error while trying to get browser timeZone', e);
}

window.__USER_LANG = 'pt-BR';
window.__USER_TZ = 'America/Sao_Paulo';

window.jQuery = jQuery;
window.j = jQuery;
jQueryNoConflict(jQuery);

window._ = lodash;

window.moment = momentTz;

try {
    var $head = document.head;

    var lang = ($head.dataset.lang || 'pt-BR').replace(/_/g, '-');
    window.__USER_LANG = lang;
    console.log('[Moment] Setting language: ' + lang);
    moment.locale(lang);

    var tz = $head.dataset.tz || moment.tz.guess();
    window.__USER_TZ = tz;
    console.log('[Moment] Setting Timezone: ' + tz);
    moment.tz.setDefault(tz);

    window.__BIMV = $head.dataset.bimv || 'u' + Date.now();
} catch (e) {
    console.warn(e);
}

tippy.setDefaultProps({plugins: [followCursor], theme: 'bim'});
window.tippy = tippy;

window.__BIM_FRAME_EVENT_NAME = 'BimFrameEvent';
// Global 'message' handler, used by frames to do some actions. See: https://github.com/sol7/bi-machine/issues/6283
try {
    const canHandleMessage = (data) => {
        return _.isBoolean(data?.forBim)
            && _.isString(data?.type);
    }

    window.addEventListener('message', ({data}) => {
        if (!canHandleMessage(data)) return;

        BimEventBus.emit(window.__BIM_FRAME_EVENT_NAME, data);
    });
} catch (e) {
    console.error(e)
}

BimEventBus.on(window.__BIM_FRAME_EVENT_NAME, data => {
    if (data.type !== 'RELOAD_PAGE') return;

    location.reload();
});