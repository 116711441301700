import mitt from 'mitt';

class BimEventBus {
  eventBus = mitt();

  emit = (event = '', data = {}) => {
    this.eventBus.emit(event, data);
  };

  on = (event = '', handler = _.noop) => {
    this.eventBus.on(event, handler);
    return () => {
      this.off(event, handler);
    };
  };

  off = (event = '', handler = _.noop) => {
    this.eventBus.off(event, handler);
  };
}

const bimEventBus = new BimEventBus();

window.bimEventBus = bimEventBus;

export default bimEventBus;
